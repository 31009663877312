.container {
  min-height: 100vh;
  background: #eee;
  padding: 30px;
}

.button-container {
  min-height: 100px;
  display: flex;
  align-items: flex-end;
}

.search-container {
  margin-bottom: 30px;
}

.search-container h1 {
  margin-bottom: 30px;
}