.post {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 100ms ease-in-out;
}

.post:hover {
  transform: scale(1.05);
}

.post img {
  max-width: 100%;
}

.post-content {
  padding: 30px;
}